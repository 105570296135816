const hostname = (window && window.location && window.location.hostname) || "";

let GIFTCARD_ENDPOINT = "";
if (hostname === "giftcard.staging.smartum.fi") {
    GIFTCARD_ENDPOINT = "https://api.staging.smartum.fi/giftcard";
}
else if (hostname === "giftcard.smartum.fi") {
    GIFTCARD_ENDPOINT = "https://api.smartum.fi/giftcard";
}
else {
    GIFTCARD_ENDPOINT = "http://localhost:3000/api/giftcard";
}

export { GIFTCARD_ENDPOINT };
