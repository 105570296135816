import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trans, useTranslation } from "react-i18next";
import "./App.scss";
import { GIFTCARD_ENDPOINT } from "./config";
import smartumLogo from "./smartumLogoDark.png";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image1Mobile from "./images/image1-mobile.png";

export default function App() {
  const { t, i18n } = useTranslation();
  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const giftCardDetails = () => {
    const urlParams = new URL(window.location.toLocaleString()).searchParams;
    const token:string = urlParams.get("token")!;
    const benefitType:string = urlParams.get("benefit")!;
    const expires:string = urlParams.get("expires")!;

    return { token, benefitType, expires };
  };

  let token: string, benefitType: string, expires: string;
  ({token, benefitType, expires} = giftCardDetails());

  useEffect(() => {
    const urlParams = new URL(window.location.toLocaleString()).searchParams;
    const language = urlParams.get("lng");
    i18n.changeLanguage(language || "fi");
    if (!token || !benefitType || !expires) {
      setError(`${t("error.somethingWentWrong")} ${t("error.openPageAgain")}`);
    }
  }, [token, benefitType, expires, i18n]);

  interface ApiError {
    code: string,
    message: string,
    param: string,
    type: string
  }

  const setErrorMessage = (apiError:ApiError) => {
    let message = "";
    switch (apiError.code) {
      case "parameter_invalid":
      case "body_invalid":
        message = t("error.openPageAgain");
        break;
      default:
        if (apiError.message.includes("has already been used")) {
          message = t("error.alreadyUsed");
          break;
        }
        if (apiError.message.includes("Not enough credits")) {
          message = t("error.notEnoughCredits");
          break;
        }
        message = t("error.tryAgainLater");
        break;
    }
    const fullErrorMsg = `${t("error.purchaseFailed")} ${message}`;
    setError(fullErrorMsg);
  };

  const callGiftcardApi = async (token: string) =>
      fetch(GIFTCARD_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({token}),
      }).then((response) => {
        if(response.ok) {return {}};
        return Promise.reject(response);
      })

  const convertToGiftcard = () => {
    setModalOpen(false);
    if (token) {
      callGiftcardApi(token)
        .then((response) => setSuccess(true))
        .catch((response) => {
          if (response.json){
            response.json().then((json: any) => {
              setErrorMessage(json.error);
            })
          }
          else setErrorMessage(response);
        });
    }
  };

  const parseExpiryDate = (expires:string) => {
    if (!expires) {
      return "";
    }
    const [day, month, year] = expires.split('.');
    return new Date(+year, +month - 1, +day).toLocaleDateString("fi");
  }

  const modal = () => (
    <div className="smartum-modal" onClick={e => e.stopPropagation()}>
      <button className="close" onClick={() => setModalOpen(false)}>
        x
      </button>
      <h4>{t("confirmBuyGiftcard")}</h4>
      <div className="d-flex flex-column flex-md-row pt-4">
        <button onClick={convertToGiftcard}>{t("confirmPayment")}</button>
        <button
          className="tertiary ml-md-5 mt-4 mt-md-0"
          onClick={() => setModalOpen(false)}
        >
          {t("cancelPayment")}
        </button>
      </div>
    </div>
  );

  const buyGiftcardContainer = () => (
    <div className="d-flex flex-column">
      {modalIsOpen && modal()}
      <div className="d-flex flex-column flex-lg-row">
        <img
          src={image1Mobile}
          alt="Nainen pelaamassa tennistä"
          className="image1"
        />
        <div className="block-1-text-container">
          <h3 className="px-5 pt-5 title">{t("giftcardInfoTitle")}</h3>
          <div className="px-5 pb-5">
            <p className="text py-4">
              <b>
                {t("benefitExpiring", {
                  benefitType: t(`${benefitType}`),
                })}
              </b>
              {parseExpiryDate(expires)}.{" "}
              <Trans i18nKey="giftcardInfo">
                <b>three months</b>
              </Trans>
            </p>
            <div className="d-flex flex-column flex-sm-row">
              <button onClick={() => setModalOpen(true)}>
                {t("buyGiftcard")}
              </button>
            </div>
          </div>
        </div>
        <img
          src={image1}
          alt="Nainen pelaamassa tennistä"
          className="image1-md"
        />
      </div>
      <div className="d-flex flex-column extra-info">
        <div className="d-flex">
          <img
            src={image2}
            alt="Nainen katsoo puhelimesta SmartumPay-sovellusta"
            className="image2"
          />
          <div className=" px-5 pt-5 block-2-text-container">
            <h3>{t("giftcardDetailsTitle")}</h3>
            <p className="text py-4">
              <Trans i18nKey="giftcardDetails">
                <br />
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const buyGiftcardContainerIsVisible = !error && !success;

  return (
    <div className="app d-flex flex-column" onClick={() => modalIsOpen && setModalOpen(false)}>
      <div className="py-5 px-5 header d-flex justify-content-between align-items-center">
        <img src={smartumLogo} alt="Smartum logo" width={170} />
        <div>
          <button
            className="secondary mr-2"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </button>
          <button
            className="secondary"
            onClick={() => i18n.changeLanguage("fi")}
          >
            FI
          </button>
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-center content-container">
        {buyGiftcardContainerIsVisible && buyGiftcardContainer()}
        {success && (
          <div className="text-container">
            <h3 className="pt-5 title">{t("afterPurchaseTitle")}</h3>
            <p className="text py-4">
              {t("afterPurchaseBlock1")}
              {benefitType !== "massage" && (
                <>
                  <br />
                  <br />
                  {t("afterPurchaseBlock2")}
                </>
              )}
            </p>
            <div className="d-flex flex-column flex-sm-row">
              {benefitType !== "massage" && (
                <button
                  className="mr-sm-5"
                  onClick={() => {
                    window.location.href =
                      i18n.language === "en"
                        ? "https://form.typeform.com/to/u0KzS5cR"
                        : "https://form.typeform.com/to/pY3moZ93";
                  }}
                >
                  {t("answerToSurvey")}
                </button>
              )}
              <button
                className="secondary mt-4 mt-sm-0"
                onClick={() => {
                  window.location.href = "https://www.smartum.fi/kayttovinkit";
                }}
              >
                {t("findVenues")}
              </button>
            </div>
          </div>
        )}
        {error && (
          <div className="text-container">
            <h4>{error}</h4>
          </div>
        )}
      </div>
      <div className={`${modalIsOpen ? "modal-open" : ""}`} />
      <div className="footer-1 d-flex flex-wrap">
        <div>
          <p className="title">Smartum Oy</p>
          <p className="text">
            Yrjönkatu 11 B <br /> 00120 Helsinki <br />
            Y-Tunnus: 2780101-9
          </p>
        </div>
        <div>
          <p className="title">Tutustu</p>
          <p className="text">
            <a href="https://www.smartum.fi/yrityksille">Yrityksille</a> <br />
            <a href="https://www.smartum.fi/kayttajalle">Käyttäjille</a> <br />
            <a href="https://www.smartum.fi/kumppaneille">Kumppaneille</a>{" "}
            <br />
          </p>
        </div>
        <div>
          <p className="title">Ratkaisut</p>
          <p className="text">
            <a href="https://www.smartum.fi/yrityksille#ratkaisut">Palvelut</a>{" "}
            <br />
            <a href="https://www.smartum.fi/yrityksille#edut">
              Työsuhde-edut
            </a>{" "}
            <br />
            <a href="https://www.smartum.fi/maksutavat">Maksutavat</a> <br />
          </p>
        </div>
      </div>
      <div className="footer-2">
        <p className="text">© 2023 Smartum Oy ・ All right reserved</p>
      </div>
    </div>
  );
}
